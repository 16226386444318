<template>
  <div>
    <div class="header">
      <ds-header title="招聘专题查看" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <div class="content">
      <a-form-model ref="ruleForm" :model="form" :labelCol="{ style: 'width: 120px' }">
        <div class="title">专场基础信息</div>
        <div class="content">
          <a-form-model-item label="招聘专场名称"> {{ form.specialName }} </a-form-model-item>
          <a-form-model-item label="所在项目" prop="projectIdList">
            <a-select style="width: 800px" mode="tags" disabled :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择"
              v-model="form.projectIdList">
              <a-select-option v-for="item in projectList"  :key="item.projectId" :value="item.projectId">
                {{ item.projectName }}
              </a-select-option> 
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="专场图片" v-if="form.specialPictureUrl">
            <img :src="form.specialPictureUrl" alt="avatar" class="imgUpload" />
          </a-form-model-item>
          <a-form-model-item label="专场介绍" v-if='form.specialIntroduce'>
            <div v-html="form.specialIntroduce"></div>
          </a-form-model-item>
          <a-form-model-item label="专场视频" v-if="form.specialVideoUrl">
            <video v-if="form.specialVideoUrl"
                   :poster="`${form.specialVideoUrl}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`"
                   :src="form.specialVideoUrl"
                   controls class="imgUpload">
            </video>
          </a-form-model-item>
          <a-form-model-item label="专场背景">
            {{ "#"+form.specialColorNumber }}
            <a-icon type="check-circle" theme="filled" :style="`color: #${form.specialColorNumber}`" />
          </a-form-model-item>
        </div>
        <div class="title">专场模块信息</div>
        <div class="content">
          <div v-for="(item, index) in form.specialModuleInfoList">
            <a-form-model-item :label="`专场模块${index+1}：`" style="text-align: right; width: 920px;" />
            <a-form-model-item label="专场模块名称">{{item.moduleName}}</a-form-model-item>
            <a-form-model-item label="专场模块图片">
              <img v-if="item.modulePictureUrl" :src="item.modulePictureUrl" alt="avatar" class="imgUpload" />
            </a-form-model-item>
          </div>
        </div>
        <div class="title">专场其他信息</div>
        <div class="content">
          <a-form-model-item label="状态"> {{ form.state}} </a-form-model-item>
          <a-form-model-item label="创建人"> {{ form.createName }} </a-form-model-item>
          <a-form-model-item label="创建时间"> {{ form.gmtCreate }} </a-form-model-item>
          <a-form-model-item label="最后修改人"> {{ form.modifiedName }}</a-form-model-item>
          <a-form-model-item label="最后修改时间"> {{ form.gmtModified }} </a-form-model-item>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from "../../../../api/socialRecruitingServicesManagement";
export default {
  name: "specialDetail",
  data() {
    return {
      form: {},
      projectList: []
    };
  },
  mounted() {
    // 获取详情数据
    this.getDetail();
    this.getproject()
  },
  methods: {
    // 获取详情数据
    async getDetail() {
      try {
        const recruitmentId = this.$route.query.id;
        const res = await api.specialDetail(recruitmentId);
        if (res.code === "200") {
          this.form = res.data;
          this.form.jobDescription = res.data.jobDescription
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
          this.form.jobRequire = res.data.jobRequire
            .replaceAll(/\r\n/g, "<br>")
            .replaceAll(/\r/g, "<br>")
            .replaceAll(/\n/g, "<br>");
          let temp = "";
          res.data.jobTypeList.forEach((item) => {
            temp = temp + "，" + item.jobTypeName;
          });
          this.form.jobTypeIds = temp.substring(1);
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    
    // 获取项目
   async getproject() {
    let res = await  api.SPECIAL_POSITION_GETPROJECT()
    this.projectList = res.data
    }
  },
};
</script>

<style lang='scss' scoped>
  .header {
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
  }
  .content {
    margin-top: 16px;
  }
  ::v-deep .ant-form-item {
    display: flex;
  }
  ::v-deep .ant-form-item-control-wrapper {
    width: 100%;
  }
  .tips {
    color: #d9d9d9;
    position: absolute;
    bottom: -40px;
    right: 0;
  }
  .imgUpload {
    max-height: 300px;
    height: expression(this.height > 300 ? '300px': true);
    max-width: 400px;
    width: expression(this.width > 400 ? '400px': true);
  }

</style>
